<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4624 7.58561L3.00537 7.58561M3.00537 7.58561L7.58031 12.1606M3.00537 7.58561L7.58031 3.01067" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconArrowLeft'
}
</script>
